import React, { CSSProperties, useEffect, useState } from "react";
import animationPNG from "./image-halo-uploading-animation.png";

const UploadingIcon = () => {
  const [frameIndex, setFrameIndex] = useState(0);
  const frames = 4;
  const frameWidth = 120;

  const nextFrame = () => {
    setFrameIndex((prevIndex) => (prevIndex + 1) % frames);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextFrame();
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, [frameIndex]);

  const containerStyle: CSSProperties = {
    position: "relative",
    width: `${frameWidth}px`,
    height: "100px",
    overflow: "hidden",
  };

  const imageStyle: CSSProperties = {
    position: "absolute",
    width: `${frameWidth * frames}px`,
    left: `-${frameIndex * frameWidth}px`,
    objectFit: "none",
  };

  return (
    <div style={containerStyle} className="my-5">
      <img src={animationPNG} alt="Animation Frame" style={imageStyle} />
    </div>
  );
};

export default UploadingIcon;
