import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useRecoilState } from "recoil";
import {
  deviceCharacteristicAtom,
  measurementDeviceAtom,
} from "../../recoil/atoms";

const EMULATOR_SERVICE3_WRITE = "2a5a20f9-0003-4b9c-9c69-4975713e0ff2";
const EMULATOR_SERVICE4_READ = "2a5a20f9-0004-4b9c-9c69-4975713e0ff2";

const CHARACTERISTIC_OPD_CH0 = "2a5a20b9-000b-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH1 = "2a5a20b9-000c-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH2 = "2a5a20b9-000d-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH3 = "2a5a20b9-000e-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH4 = "2a5a20b9-000f-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH5 = "2a5a20b9-0010-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH6 = "2a5a20b9-0011-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_OPD_CH7 = "2a5a20b9-0012-4b9c-9c69-4975713e0ff2";

const CHARACTERISTIC_DPOT0 = "2a5a20b9-0017-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_DPOT1 = "2a5a20b9-0018-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_DPOT2 = "2a5a20b9-0019-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_DPOT3 = "2a5a20b9-001a-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_DPOT4 = "2a5a20b9-001b-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_DPOT5 = "2a5a20b9-001c-4b9c-9c69-4975713e0ff2";
const CHARACTERISTIC_DPOT6 = "2a5a20b9-001d-4b9c-9c69-4975713e0ff2";

const ConnectedDevice: React.FC = () => {
  ///////////////////////////////////////
  // Works in Chrome with following chrome://flags/ enabled:
  // #enable-experimental-web-platform-features
  // #enable-web-bluetooth-new-permissions-backend
  // And one for self-signed SSL:
  // #allow-insecure-localhost
  ///////////////////////////////////////

  const [notifier, setNotifier] = useState(false);
  const [measurementDevice, setMeasurementDevice] = useRecoilState(
    measurementDeviceAtom
  );
  const [deviceCharacteristic, setDeviceCharacteristic] = useRecoilState(
    deviceCharacteristicAtom
  );

  useEffect(() => {
    if (measurementDevice.device) {
      const handleDisconnection = async () => {
        console.log("Device disconnected");
        console.log("Attempting to reconnect...");
        try {
          // @ts-ignore
          const server = await measurementDevice.device.gatt?.connect();
          console.log("Reconnected to server: ", server);
          if (server) {
            const service = await server.getPrimaryService(
              EMULATOR_SERVICE4_READ
            );
            const characteristic = await service.getCharacteristic(
              CHARACTERISTIC_OPD_CH0
            );
            setDeviceCharacteristic({
              characteristic: characteristic || null,
            });
            console.log("Reconnected to characteristic: ", characteristic);
            // Add event listener for characteristic value changes again if needed
            characteristic?.addEventListener(
              "characteristicvaluechanged",
              (event) => {
                if (!event.target) return;
                // @ts-ignore
                const value = event.target.value;
                const timestampSection = new Uint8Array(value.buffer).slice(
                  4,
                  20
                );

                const timeEncoded = [];
                for (let i = 0; i < timestampSection.length; i++) {
                  timeEncoded.push(String.fromCharCode(timestampSection[i]));
                }
              }
            );
            setMeasurementDevice((prev) => ({
              ...prev,
              isMeasurementDeviceConnected: true,
              device: measurementDevice.device,
            }));
          }
        } catch (error) {
          console.error("Reconnection failed: ", error);
          setMeasurementDevice((prev) => ({
            ...prev,
            isMeasurementDeviceConnected: false,
            device: null,
          }));
        }
      };

      const device = measurementDevice.device;
      device.addEventListener("gattserverdisconnected", handleDisconnection);

      return () => {
        device.removeEventListener(
          "gattserverdisconnected",
          handleDisconnection
        );
      };
    }
  }, [measurementDevice.device, setMeasurementDevice, setDeviceCharacteristic]);

  async function connectDevice() {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: [EMULATOR_SERVICE3_WRITE, EMULATOR_SERVICE4_READ],
      });
      console.log("Device found: ", device);
      setMeasurementDevice((prev) => ({
        ...prev,
        isMeasurementDeviceConnected: true,
        device: device,
      }));

      const server = await device.gatt?.connect();
      console.log("Server connected: ", server);

      const service = await server?.getPrimaryService(EMULATOR_SERVICE4_READ);
      console.log("Primary service: ", service);

      const characteristic = await service?.getCharacteristic(
        CHARACTERISTIC_OPD_CH0
      );
      setDeviceCharacteristic({
        characteristic: characteristic || null,
      });
      console.log("Characteristic: ", characteristic);

      characteristic?.addEventListener(
        "characteristicvaluechanged",
        (event) => {
          if (!event.target) return;
          // @ts-ignore
          const value = event.target.value;
          const timestampSection = new Uint8Array(value.buffer).slice(4, 20);

          const timeEncoded = [];
          for (let i = 0; i < timestampSection.length; i++) {
            timeEncoded.push(String.fromCharCode(timestampSection[i]));
          }
        }
      );
    } catch (error) {
      console.error("Error during connection: ", error);
      setMeasurementDevice((prev) => ({
        ...prev,
        isMeasurementDeviceConnected: false,
      }));
    }
  }

  async function signOut() {
    if (deviceCharacteristic.characteristic) {
      try {
        await deviceCharacteristic.characteristic.stopNotifications();
        console.log("Notifications stopped");
        setNotifier(false);
      } catch (error) {
        console.log("Stopping notifications failed: " + error);
      }
    } else {
      console.log("No characteristic found yet");
    }
  }

  return (
    <div className="connected-device">
      {measurementDevice.isMeasurementDeviceConnected ? (
        <Container>
          <Row>
            <Col>
              <span>
                You are connected to:{" "}
                <span style={{ color: "black" }}>
                  (
                  {measurementDevice.device?.name ||
                    measurementDevice.device?.id}
                  )
                </span>
              </span>
            </Col>
          </Row>
          {deviceCharacteristic.characteristic && (
            <Row>
              <Col>
                {!notifier && (
                  <span>
                    <Button
                      className="button-connect-device"
                      onClick={connectDevice}
                    >
                      Connect Another Device
                    </Button>
                  </span>
                )}
                {notifier && (
                  <Button className="button-connect-device" onClick={signOut}>
                    Sign out from notifications
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Container>
      ) : (
        <Container>
          <Row>
            <Col xs={2}>
              <FontAwesomeIcon className="icon" icon={faBan} size="lg" />
            </Col>
            <Col xs={10}>
              <div className="text">You are not connected to any device</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button">
                <Button
                  className="button-connect-device"
                  onClick={connectDevice}
                >
                  Scan for devices
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default ConnectedDevice;
