import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";

import "./styles.scss";

Chart.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

const DynamicChart = ({ channel, data, color }) => {
  const chartRef = useRef(null);

  const yValues = data.map((point) => point.y);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  const margin = 0.001;
  const adjustedYMin = yMin - margin;
  const adjustedYMax = Math.ceil(yMax * 100) / 100 + margin;

  const options = {
    plugins: {
      title: {
        display: true,
        text: `Channel ${channel}`,
        font: {
          size: 16,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        min: adjustedYMin,
        max: adjustedYMax,
        title: {
          display: true,
          text: "Current (µA)",
        },
      },
      x: {
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Time",
        },
      },
    },
  };

  const chartData = {
    datasets: [
      {
        label: `Channel ${channel} Data`,
        data: data,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 2,
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="dynamic-chart">
      <Line
        height={520}
        width={400}
        ref={chartRef}
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default DynamicChart;
