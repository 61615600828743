import { atom } from "recoil";

interface CurrentSiteInterface {
  siteId: string;
  siteName: string;
}

export const currentSiteAtom = atom<CurrentSiteInterface>({
  key: "currentSiteForMobile",
  default: {
    siteId: "",
    siteName: "",
  },
});
