import React, { useCallback, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import SendResultsModal from "../../modals/SendResultsModal/SendResultsModal";

interface SendResults {
  name: "SendResults";
}

type VisibleModal = SendResults | undefined;

interface Props {
  handleUpload: () => void;
}

const UploadResultsItem: React.FC<Props> = ({ handleUpload }) => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback(() => {
    setVisibleModal({ name: "SendResults" });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "SendResults":
          return (
            <SendResultsModal
              onClose={handleModalClose}
              handleSend={handleUpload}
            />
          );
      }
    }
  }, [handleModalClose, handleUpload, visibleModal]);

  return (
    <div className="upload-results-item">
      <div className="upload-icon">
        <FontAwesomeIcon icon={faCloudArrowUp} />
      </div>
      <div className="text">
        <div className="title" onClick={() => handleModalOpen()}>
          Click Here To Upload Results
        </div>
        <div className="description">
          Results will be removed from your mobile device after upload.
        </div>
      </div>

      {renderModals()}
    </div>
  );
};

export default UploadResultsItem;
