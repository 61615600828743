import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "../../recoil/atoms";
import UploadingIcon from "../UploadingIcon/UploadingIcon";
import "./styles.scss";

interface Props {
  setIsUploading: (val: boolean) => void;
}

const Uploading: React.FC<Props> = ({ setIsUploading }) => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <div className="container d-flex flex-column justify-content-center pt-5 h-100 align-items-center">
      <h1>Sending Results...</h1>
      <UploadingIcon />
      <div className="button">
        <Button
          onClick={() => {
            setIsUploading(false);
            navigate(
              `organization/${userInfo.currentOrganizationName}/results`
            );
          }}
          className="cancel-button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default Uploading;
