import {
  faFlaskVial,
  faHouse,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKeycloak } from "@react-keycloak/web";
import { get } from "idb-keyval";
import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import "./style.scss";
import Navbar from "react-bootstrap/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  currentSiteAtom,
  deviceCharacteristicAtom,
  resultsListAtom,
  userInfoAtom,
} from "../../recoil/atoms";

const BottomNavigation: React.FC = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  const results = useRecoilValue(resultsListAtom);
  const location = useLocation();

  const userInfo = useRecoilValue(userInfoAtom);
  const currentSite = useRecoilValue(currentSiteAtom);
  const deviceCharacteristic = useRecoilValue(deviceCharacteristicAtom);

  useEffect(() => {
    const fetchResultsCount = async () => {
      const resp = await get("measurements");
      if (resp !== undefined) {
        setResultsCount(resp.length);
      }
    };

    fetchResultsCount();
  }, [results]);

  useEffect(() => {
    setResultsCount(results.length);
  }, [results]);

  if (keycloak.authenticated) {
    return (
      <div className="bot-nav">
        <Navbar className="bottom-navigation" variant="dark">
          <div
            className={`home-page-container ${
              location.pathname.includes("start-page") ? "active" : ""
            }`}
          >
            <Nav>
              <Nav.Link
                onClick={() => {
                  navigate(
                    `organization/${userInfo.currentOrganizationName}/start-page`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="icon-logo"
                  icon={faHouse}
                  size="lg"
                />
              </Nav.Link>
            </Nav>
          </div>
          <div
            className={`tests-container ${
              location.pathname.includes("new-test") ? "active" : ""
            }`}
          >
            <Nav>
              <Nav.Link
                disabled={
                  !deviceCharacteristic.characteristic ||
                  currentSite.siteId === ""
                }
                onClick={() => {
                  navigate(
                    `organization/${userInfo.currentOrganizationName}/new-test`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="icon-logo"
                  icon={faFlaskVial}
                  size="lg"
                />
              </Nav.Link>
            </Nav>
          </div>
          <div
            className={`results-container ${
              location.pathname.includes("results") ? "active" : ""
            }`}
          >
            <Nav>
              <Nav.Link
                onClick={() => {
                  navigate(
                    `organization/${userInfo.currentOrganizationName}/results`
                  );
                }}
              >
                <div className="button-with-notification">
                  <FontAwesomeIcon
                    className="icon-logo"
                    icon={faSquarePollHorizontal}
                    size="lg"
                  />
                  {resultsCount !== 0 && resultsCount !== undefined && (
                    <div className="notification">{resultsCount}</div>
                  )}
                </div>
              </Nav.Link>
            </Nav>
          </div>
        </Navbar>
      </div>
    );
  } else return null;
};

export default BottomNavigation;
