import { atom } from "recoil";

interface DeviceCharacteristicInterface {
  characteristic: BluetoothRemoteGATTCharacteristic | null;
}

export const deviceCharacteristicAtom = atom<DeviceCharacteristicInterface>({
  key: "deviceCharacteristic",
  default: {
    characteristic: null,
  },
});
