import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { Button, Form, FormText, ListGroup, Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { get, set } from "idb-keyval";
import { useKeycloak } from "@react-keycloak/web";
import { ResultToSend } from "../../types";
import { convertDate } from "../../utils/convertDate";
import { securedApi } from "../../api";
import { useRecoilState, useRecoilValue } from "recoil";
import { resultsListAtom, userInfoAtom } from "../../recoil/atoms";

interface Props {
  onClose: () => void;
  date: string | undefined;
  device: string | undefined;
  elementId: number;
}

interface Tag {
  value: string;
  label: string;
}

const TestDetailsModal: React.FC<Props> = ({
  onClose,
  date,
  device,
  elementId,
}) => {
  const [results, setResults] = useState<ResultToSend[] | undefined>(undefined);
  const [inputTags, setInputTags] = useState<Tag[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const { keycloak, initialized } = useKeycloak();
  const [tags, setTags] = useState<string[] | undefined>(undefined);
  const [tagsList, setTagsList] = useState([]);
  const [, setResultsRecoil] = useRecoilState(resultsListAtom);

  const userInfo = useRecoilValue(userInfoAtom);

  useEffect(() => {
    get("results").then((res) => {
      setResults(res);
      setComment(res[elementId].comment);
      setTags(res[elementId].tags);
    });

    const getUserInfo = () => {
      if (keycloak?.authenticated) {
        keycloak.loadUserInfo().then((response) => {
          // @ts-ignore
          setUserName(response.name || response.preferred_username);
        });
      }
    };

    getUserInfo();
  }, [keycloak]);

  useEffect(() => {
    const loadTags = () => {
      if (tags) {
        const tagsToLoad = tags.map((el) => {
          return { value: el, label: el } as Tag;
        });
        setInputTags(tagsToLoad);
      }
    };
    loadTags();
  }, [tags]);

  useEffect(() => {
    securedApi
      .get(`/api/organizations/${userInfo.currentOrganizationId}/tags`)
      .then((response) => {
        let options = response.data.tags.map((el: any) => {
          let option = { value: el.tag, label: el.tag };
          return option;
        });
        setTagsList(options);
      });
  }, [userInfo.currentOrganizationId]);

  const handleUpdateResult = useCallback(
    (id: number) => {
      const updatedResultsList = results;

      // @ts-ignore
      updatedResultsList[id] = {
        // @ts-ignore
        ...updatedResultsList[id],
        comment: comment,
        tags: tags,
      };
      set("results", updatedResultsList).catch((error) => console.error(error));
      setResults(updatedResultsList);
      // @ts-ignore
      setResultsRecoil(updatedResultsList);
    },
    [comment, results, tags]
  );

  const handleTagsInput = (value: Tag[]) => {
    const updatedTags = value.map((el) => {
      return el.value;
    });

    setTags(updatedTags);
  };

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    handleUpdateResult(elementId);
    onClose();
  };

  return (
    <Modal
      show
      animation={false}
      onHide={onClose}
      className="modal-test-details"
    >
      <Modal.Header closeButton className="header">
        <Modal.Title>Test Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <ListGroup horizontal className="my-2">
          <ListGroup.Item className="item">
            <FormText>Date and Time</FormText>
            <br />
            {convertDate(date)}
          </ListGroup.Item>
        </ListGroup>

        <ListGroup horizontal className="my-2">
          <ListGroup.Item className="item">
            <FormText>User</FormText>
            <br />
            {userName}
          </ListGroup.Item>
        </ListGroup>

        <ListGroup horizontal className="my-2">
          <ListGroup.Item className="item" style={{ width: "100%" }}>
            <FormText>Device + Cartridge</FormText>
            <br />
            {device} +&nbsp;
            {results !== undefined
              ? `Cartridge${results[elementId].cartridge}`
              : null}
          </ListGroup.Item>
        </ListGroup>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="record-form"
        >
          <Form.Group className="mb-3" controlId="Form.ControlInput1">
            <Form.Label style={{ flex: 1, flexDirection: "row" }}>
              <span>
                Tags&nbsp;
                <FontAwesomeIcon className="tag" icon={faTag} />
              </span>
            </Form.Label>
            <Select
              multi
              create
              placeholder="Add new tags..."
              options={tagsList}
              color="#4a800b"
              values={inputTags}
              onChange={(value) => handleTagsInput(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Form.ControlTextarea1">
            <Form.Label>
              <span>
                Comment&nbsp;
                <FontAwesomeIcon className="comment" icon={faCommentDots} />
              </span>
            </Form.Label>
            <Form.Control
              as="textarea"
              onKeyPress={(e) => {
                e.code === "Enter" && e.preventDefault();
              }}
              rows={4}
              placeholder="Enter Comment"
              defaultValue={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>
          <Button className="btn-modal-save" type="button" onClick={submitForm}>
            Save
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer"></Modal.Footer>
    </Modal>
  );
};

export default TestDetailsModal;
