import { atom } from "recoil";

interface MeasurementDeviceInterface {
  isMeasurementDeviceConnected: boolean;
  device: BluetoothDevice | null;
}

export const measurementDeviceAtom = atom<MeasurementDeviceInterface>({
  key: "measurementDevice",
  default: {
    isMeasurementDeviceConnected: false,
    device: null,
  },
});
