import React from "react";
import "./style.scss";
import { Button, Form, Modal } from "react-bootstrap";

interface Props {
  onClose: () => void;
  handleRemoveListItem: (id: number) => void;
  elementId: number;
}

const ConfirmDeleteModal: React.FC<Props> = ({
  onClose,
  handleRemoveListItem,
  elementId,
}) => {
  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Modal show onHide={onClose} className="modal-confirm-delete">
      <Modal.Header closeButton className="header">
        <Modal.Title>Delete result</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-4">
            <Form.Label>
              Are you sure you want to Delete this result?
            </Form.Label>
          </Form.Group>
          <Button
            className="btn-modal-delete"
            type="submit"
            onClick={() => {
              handleRemoveListItem(elementId);
            }}
          >
            Delete
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteModal;
