import React from "react";
import "./style.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

interface Props {
  onClose: () => void;
}

const LogoutConfirmationModal: React.FC<Props> = ({ onClose }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <Modal show onHide={onClose} className="modal-logout">
      <Modal.Header closeButton className="header">
        <Modal.Title>Confirm Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-4">
            <Form.Label>Are you sure you want to log out?</Form.Label>
          </Form.Group>
          <Button
            className="btn-modal-save"
            type="submit"
            onClick={() => {
              navigate("home");
              keycloak.logout();
            }}
          >
            Log out
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutConfirmationModal;
