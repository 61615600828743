import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "idb-keyval";
import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { Col, Row } from "react-bootstrap";
import { useRecoilState } from "recoil";
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal/ConfirmDeleteModal";
import TestDetailsModal from "../../modals/TestDetailsModal/TestDetailsModal";
import { resultsListAtom } from "../../recoil/atoms";
import { convertDate } from "../../utils/convertDate";

interface Props {
  id: number;
  date: string | undefined;
  device: string | undefined;
  value?: string | undefined;
  onRemoveElement: (id: number) => void;
}

interface Modals {
  name: "ConfirmDelete" | "TestDetails";
}

type VisibleModal = Modals | undefined;

const ListItem: React.FC<Props> = ({ id, date, device, onRemoveElement }) => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [resultsList, setResultsList] = useState([]);
  const [, setResults] = useRecoilState(resultsListAtom);

  useEffect(() => {
    get("results").then((res) => setResultsList(res));
  }, []);

  const handleRemoveListItem = useCallback(
    (id: number) => {
      onRemoveElement(id);
      setResults(resultsList);
    },
    [onRemoveElement, resultsList, setResults]
  );

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType: Modals) => {
    setVisibleModal({ name: modalType.name });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "ConfirmDelete":
          return (
            <ConfirmDeleteModal
              onClose={handleModalClose}
              handleRemoveListItem={handleRemoveListItem}
              elementId={id}
            />
          );
        case "TestDetails":
          return (
            <TestDetailsModal
              date={date}
              device={device}
              elementId={id}
              onClose={handleModalClose}
            />
          );
      }
    }
  }, [date, device, handleModalClose, handleRemoveListItem, id, visibleModal]);

  return (
    <div className="list-item">
      <Row>
        <Col
          xs={10}
          className="description"
          onClick={() => handleModalOpen({ name: "TestDetails" })}
          style={{ overflowWrap: "break-word", maxWidth: "100%" }}
        >
          <Row>{convertDate(date)}</Row>
          <Row className="d-flex justify-content-start">
            <div className="deviceContainer">
              <div style={{ minWidth: "60px", paddingLeft: 0 }}>Device:</div>{" "}
              <span style={{ textAlign: "start" }}>{device}</span>
            </div>
          </Row>
          <Row className="icons"></Row>
        </Col>
        <Col xs={2} className="icon delete-icon">
          <FontAwesomeIcon
            onClick={() => handleModalOpen({ name: "ConfirmDelete" })}
            icon={faTrashCan}
          />
        </Col>
      </Row>
      {renderModals()}
    </div>
  );
};

export default ListItem;
