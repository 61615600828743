import { get, set } from "idb-keyval";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { securedApi } from "../../api";
import { resultsListAtom } from "../../recoil/atoms";
import { ResultToSend } from "../../types";
import ListItem from "../ListItem/ListItem";
import UploadResultsItem from "../UploadResultsItem/UploadResultsItem";

const API_URL: string | undefined = process.env.REACT_APP_API;

interface Props {
  setIsUploading: (val: boolean) => void;
}

const ResultsList: React.FC<Props> = ({ setIsUploading }) => {
  useState<{ siteId: string; siteName: string }>();
  const [resultsToDisplay, setResultsToDisplay] = useState<
    ResultToSend[] | undefined
  >(undefined);
  const [results, setResults] = useRecoilState(resultsListAtom);
  const navigate = useNavigate();

  const [measurements, setMeasurements] = useState([]);

  const fetchData = () => {
    get("measurements").then((res) => {
      setMeasurements(res);
    });
  };

  const handleRemoveListItem = async (index: number) => {
    const updatedMeasurements = measurements.filter((_, i) => i !== index);
    await set("measurements", updatedMeasurements);
    setMeasurements(updatedMeasurements);
  };

  const handleUpload = async (): Promise<void> => {
    setIsUploading(true);

    try {
      for (const measurement of measurements) {
        const formData = new FormData();
        // @ts-ignore
        formData.append("resultTS", measurement.resultTS);
        // @ts-ignore
        formData.append("sitename", measurement.sitename);
        // @ts-ignore
        formData.append("device", measurement.device);
        // @ts-ignore
        formData.append("cartridge", measurement.cartridge);
        // @ts-ignore
        formData.append(
          "filedata",
          // @ts-ignore
          new Blob([JSON.stringify(measurement.data)], {
            type: "application/json",
          }),
          "measurement.json"
        );

        await securedApi.post(`${API_URL}/api/measurement`, formData);
      }

      await set("measurements", []);
      setResultsToDisplay([]);
      setResults([]);
      setTimeout(() => {
        setIsUploading(false);
        navigate("/success");
      }, 3000);
    } catch (error) {
      console.error("Error uploading measurements:", error);
      navigate("/*");
    }
  };

  useEffect(() => {
    fetchData();
  }, [setResultsToDisplay]);

  return (
    <div className="results-list-container">
      <div className="list">
        {measurements !== undefined && measurements.length > 0 ? (
          <ListGroup>
            {measurements.map(({ resultTS, device }, idx) => {
              return (
                <ListGroup.Item key={idx} className="item">
                  <ListItem
                    id={idx}
                    date={resultTS}
                    device={device}
                    onRemoveElement={handleRemoveListItem}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <div>There are no results to upload</div>
        )}
      </div>
      {measurements && measurements.length > 0 && (
        <UploadResultsItem handleUpload={handleUpload} />
      )}
    </div>
  );
};

export default ResultsList;
