import React from "react";
import "./style.scss";
import "../style-common.scss";
import ConnectedDevice from "../../components/ConnectedDevice/ConnectedDevice";
import ResultsToSend from "../../components/ResultsToSend/ResultsToSend";
import SiteSelect from "../../components/SiteSelect/SiteSelect";

const StartPage: React.FC = () => {
  return (
    <div className="start-page">
      <div className="header">
        <h1>Welcome to h-ALO!</h1>
      </div>
      <ResultsToSend />
      <SiteSelect />
      <ConnectedDevice />
      <h5 className="bluetooth-note">
        Remember that the Bluetooth <i className="bi-bluetooth" /> must be
        turned on.
      </h5>
    </div>
  );
};

export default StartPage;
