import React, { useCallback, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./style.scss";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import LogoutConfirmationModal from "../../modals/LogoutConfirmationModal/LogoutConfirmationModal";

interface LogoutModal {
  name: "Logout";
}

type VisibleModal = LogoutModal | undefined;

const TopBar: React.FC = () => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const handleLogo = () => {
    if (keycloak?.authenticated) {
      return navigate("home");
    }
  };

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback(() => {
    setVisibleModal({ name: "Logout" });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "Logout":
          return <LogoutConfirmationModal onClose={handleModalClose} />;
      }
    }
  }, [handleModalClose, visibleModal]);

  return (
    <Navbar className="navbar-style sticky-top" variant="dark">
      <Navbar.Brand className="nav-style ml-4">
        <Nav.Item>
          <Nav.Link
            onClick={() => handleLogo()}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="custom-logo">
              <img
                src={require("../../common/images/logo.png")}
                width="50"
                height="50"
                alt="h-ALO logo"
              />
            </div>
          </Nav.Link>
        </Nav.Item>
      </Navbar.Brand>
      <div className="username-login-container">
        <Navbar.Text className="justify-content-end">
          <Nav>
            {keycloak.authenticated ? (
              <div className="login-logout-container">
                <Nav.Link
                  className="logout-navbar"
                  onClick={() => handleModalOpen()}
                >
                  <FontAwesomeIcon
                    className="logout-icon"
                    icon={faRightFromBracket}
                    size="lg"
                  />
                </Nav.Link>
              </div>
            ) : null}
          </Nav>
        </Navbar.Text>
      </div>
      {renderModals()}
    </Navbar>
  );
};

export default TopBar;
