import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoAtom, currentSiteAtom } from "../../recoil/atoms";
import { securedApi } from "../../api";
import { set } from "idb-keyval";
import { Sites } from "../../types";

const API_URL: string | undefined = process.env.REACT_APP_API;

interface Props {
  onClose: () => void;
}

const SelectSiteModal: React.FC<Props> = ({ onClose }) => {
  const [sites, setSites] = useState<Sites | undefined>(undefined);
  const [, setCurrentSite] = useRecoilState(currentSiteAtom);

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const userInfo = useRecoilValue(userInfoAtom);

  useEffect(() => {
    if (userInfo.currentOrganizationId) {
      securedApi
        .get(
          `${API_URL}/api/organizations/${userInfo.currentOrganizationId}/sites`
        )
        .then((res) => {
          setSites(res.data.sites);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [userInfo]);

  const handleSiteSelect = (site: Sites) => {
    setCurrentSite({ siteId: site.siteId, siteName: site.name });
    set("currentSite", { siteId: site.siteId, siteName: site.name }).catch(
      (error) => console.error(error)
    );
    onClose();
  };

  return (
    <Modal show onHide={onClose} className="modal-logout">
      <Modal.Header closeButton className="header">
        <Modal.Title>Select Site</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-4">
            <ListGroup>
              {sites !== undefined
                ? sites.map((site: Sites) => {
                    return (
                      <ListGroup.Item
                        key={site.siteId}
                        onClick={() => handleSiteSelect(site)}
                      >
                        {site.name}
                      </ListGroup.Item>
                    );
                  })
                : null}
            </ListGroup>
          </Form.Group>

          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SelectSiteModal;
