import { faFlaskVial } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, set } from "idb-keyval";
import React, { useEffect } from "react";
import "./style.scss";
import "../style-common.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  deviceCharacteristicAtom,
  measurementDeviceAtom,
  resultsListAtom,
  userInfoAtom,
} from "../../recoil/atoms";

import { ResultToSend } from "../../types";
import getCurrentEmulatorValue from "../../utils/getCurrentEmulatorValue";

const StartNewTest: React.FC = () => {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(userInfoAtom);
  const deviceCharacteristic = useRecoilValue(deviceCharacteristicAtom);
  const measurementDevice = useRecoilValue(measurementDeviceAtom);

  const [, setResults] = useRecoilState(resultsListAtom);

  useEffect(() => {
    const resultsWorking: ResultToSend[] = [];

    getCurrentEmulatorValue(
      deviceCharacteristic.characteristic,
      measurementDevice.device,
      userInfo.name
    )
      .then((resp) => {
        get("results")
          .then((res) => {
            if (res !== undefined) {
              resultsWorking.push(...res);
            }
          })
          .then(() => {
            resultsWorking.push(resp as ResultToSend);
            setResults(resultsWorking);
            set("results", resultsWorking).catch((error) =>
              console.error(error)
            );
          });
      })
      .catch((error) => {
        console.log(error);
        navigate(`/organization/${userInfo.currentOrganizationName}/*`);
      });
  }, [
    deviceCharacteristic.characteristic,
    measurementDevice.device,
    navigate,
    setResults,
    userInfo.currentOrganizationName,
    userInfo.name,
  ]);

  return (
    <div className="test-in-progress">
      <div className="header">
        <h1>Test In Progress...</h1>
      </div>
      <div className="picture">
        <FontAwesomeIcon className="icon" icon={faFlaskVial} />
      </div>
      <div className="button">
        <Button
          className="button-finish"
          onClick={() =>
            navigate(
              `/organization/${userInfo.currentOrganizationName}/success-test`
            )
          }
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

export default StartNewTest;
