import { get, set } from "idb-keyval";
import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import "../style-common.scss";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { securedApi } from "../../api";
import { resultsListAtom, userInfoAtom } from "../../recoil/atoms";

const API_URL: string | undefined = process.env.REACT_APP_API;

const SuccessTestPage: React.FC = () => {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(userInfoAtom);
  const [results, setResults] = useRecoilState(resultsListAtom);

  const [siteInfo, setSiteInfo] =
    useState<{ siteId: string; siteName: string }>();
  const [resultsList, setResultsList] = useState([]);
  const [singleResult, setSingleResult] = useState();

  useEffect(() => {
    get("results").then((res) => {
      setSingleResult(res[res.length - 1]);
      setResultsList(res);
      get("currentSite").then((site) => {
        setSiteInfo(site);
      });
    });
  }, []);

  const handleUpload = (): void => {
    securedApi
      .post(`${API_URL}/api/sites/${siteInfo?.siteId}/results`, singleResult)
      .then(() => {
        const newResultsList = resultsList.slice(0, resultsList.length - 1);

        set("results", newResultsList).catch((error) => console.error(error));
        navigate(`/success`);
        setResults(results.slice(0, results.length - 1));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="success-test">
      <div className="header">
        <h1>Success!</h1>
      </div>
      <div className="subheader">
        Remember to send your results to the server.
      </div>
      <Image
        className="picture"
        src={require("../../common/images/successTest_screen_background.png")}
      />
      <div className="buttons">
        <Button
          onClick={() => {
            handleUpload();
          }}
          className="btn-result"
        >
          <span className="btn-content">Send Last Result Now</span>
        </Button>
        <br></br>
        <Button
          onClick={() =>
            navigate(
              `/organization/${userInfo.currentOrganizationName}/new-test`
            )
          }
          className="btn-test"
        >
          <span className="btn-content">Start New Test</span>
        </Button>
      </div>
    </div>
  );
};

export default SuccessTestPage;
