import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../pages/HomePage/Home";
import TopBar from "../components/TopBar/TopBar";
import { useRecoilState } from "recoil";
import { userInfoAtom } from "../recoil/atoms";
import { useKeycloak } from "@react-keycloak/web";
import ProtectedRoute from "./ProtectedRoute";
import Error from "../pages/ErrorPage/Error";
import StartPage from "../pages/StartPage/StartPage";
import Success from "../pages/SuccessPage/Success";
import BottomNavigation from "../components/BottomNavigation/BottomNavigation";
import StartNewTest from "../pages/StartNewTest/StartNewTest";
import TestInProgress from "../pages/TestInProgress/TestInProgress";
import SuccessTestPage from "../pages/SuccessTestPage/SuccessTestPage";
import ResultsPage from "../pages/ResultsPage/ResultsPage";
import { securedApi, useApiAuthInterceptor } from "../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

const AppRouter: React.FC = () => {
  useApiAuthInterceptor();

  const { keycloak, initialized } = useKeycloak();

  const [, setUserInfo] = useRecoilState(userInfoAtom);

  useEffect(() => {
    const configAx = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (keycloak?.authenticated) {
      securedApi
        .get(`${API_URL}/api/userinfo`, configAx)
        .then((res1) => {
          if (!res1.data.userinfo.isAdmin) {
            securedApi
              .get(
                `${API_URL}/api/organizations/${res1.data.userinfo.organizationId}`,
                configAx
              )
              .then((res2) => {
                setUserInfo({
                  name: res1.data.userinfo.name,
                  isAdmin: res1.data.userinfo.isAdmin,
                  isManager: res1.data.userinfo.isManager,
                  organizationsId: [],
                  organizationsName: [],
                  currentOrganizationId: res1.data.userinfo.organizationId,
                  currentOrganizationName: res2.data.organization.name,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            securedApi
              .get(`${API_URL}/api/organizations`, configAx)
              .then((res2) => {
                setUserInfo({
                  name: res1.data.userinfo.name,
                  isAdmin: res1.data.userinfo.isAdmin,
                  isManager: res1.data.userinfo.isManager,
                  organizationsId: res1.data.userinfo.organizationId,
                  organizationsName: res2.data.organizations,
                  currentOrganizationId:
                    res2.data.organizations[0].organizationId,
                  currentOrganizationName:
                    localStorage.getItem("organization") ||
                    res2.data.organizations[0].name,
                });
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [keycloak, initialized, setUserInfo]);

  return (
    <>
      <BrowserRouter>
        <TopBar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route
            path="organization/:name/start-page"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <StartPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="organization/:name/new-test"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <StartNewTest />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="organization/:name/test-in-progress"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <TestInProgress />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="organization/:name/success-test"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <SuccessTestPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="organization/:name/results"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <ResultsPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="success" element={<Success />} />
          {/*TODO Do we need stage of sending result with button complete?*/}
          {/*<Route path="sending-results" element={<SendingResults />} />*/}
          <Route path="*" element={<Error />} />
        </Routes>
        <BottomNavigation />
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
