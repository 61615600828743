import React from "react";
import "./style.scss";
import "../style-common.scss";
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Error: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <h1 className="header">Oops!</h1>
      <h3 className="subheader">Something went wrong...</h3>
      <Image
        className="picture"
        src={require("../../common/images/error_screen_background.png")}
      />
      <div className="button">
        <Button className="button-go-home" onClick={() => navigate("/Home")}>
          Go Back to Start Page
        </Button>
      </div>
    </div>
  );
};

export default Error;
