import { ReactKeycloakProvider } from "@react-keycloak/web";

import Keycloak from "keycloak-js";
import React, { useEffect } from "react";
import "./App.scss";
import keycloakConf from "./keycloak";
import AppRouter from "./routes/AppRouter";

// @ts-ignore
const keycloak = new Keycloak(keycloakConf);

const App: React.FC = () => {
  useEffect(() => {});

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={({ token }) => {
        localStorage.setItem("keycloakToken", token as string);
      }}
    >
      <div className="App">
        <AppRouter />
      </div>
    </ReactKeycloakProvider>
  );
};

export default App;
