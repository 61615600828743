import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { faCloudArrowUp, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, set } from "idb-keyval";
import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaPlay, FaStop } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { securedApi } from "../../api";
import DynamicChart from "../../components/DynamicChart/DynamicChart";
import {
  currentSiteAtom,
  deviceCharacteristicAtom,
  measurementDeviceAtom,
  resultsListAtom,
  userInfoAtom,
} from "../../recoil/atoms";
import "./style.scss";

const EMULATOR_SERVICE3_WRITE = "2a5a20f9-0003-4b9c-9c69-4975713e0ff2";
const EMULATOR_SERVICE4_READ = "2a5a20f9-0004-4b9c-9c69-4975713e0ff2";

const CHARACTERISTIC_OPD = [
  "2a5a20b9-000b-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH0
  "2a5a20b9-000c-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH1
  "2a5a20b9-000d-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH2
  "2a5a20b9-000e-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH3
  "2a5a20b9-000f-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH4
  "2a5a20b9-0010-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH5
  "2a5a20b9-0011-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH6
  "2a5a20b9-0012-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_OPD_CH7
];

const CHARACTERISTIC_DPOT = [
  "2a5a20b9-0017-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT0
  "2a5a20b9-0018-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT1
  "2a5a20b9-0019-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT2
  "2a5a20b9-001a-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT3
  "2a5a20b9-001b-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT4
  "2a5a20b9-001c-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT5
  "2a5a20b9-001d-4b9c-9c69-4975713e0ff2", // CHARACTERISTIC_DPOT6
];

const API_URL: string | undefined = process.env.REACT_APP_API;

interface MeasurementData {
  value: number;
  timestamp: string;
}

interface DataToSend {
  [key: string]: MeasurementData[];
}

const StartNewTest: React.FC = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoAtom);
  const [selectedChannel, setSelectedChannel] = useState<number | null>(1);
  const [data, setData] = useState<Array<Array<{ x: number; y: number }>>>(
    Array.from({ length: 8 }, () => [])
  );
  const [dataToSend, setDataToSend] = useState<DataToSend>({});

  const [readMeasures, setReadMeasures] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [testCompleted, setTestCompleted] = useState<boolean>(false);
  const [measuring, setMeasuring] = useState<boolean>(false);

  const [deviceCharacteristic, setDeviceCharacteristic] = useRecoilState(
    deviceCharacteristicAtom
  );

  const [measurementDevice, setMeasurementDevice] = useRecoilState(
    measurementDeviceAtom
  );
  const [, setResults] = useRecoilState(resultsListAtom);

  const currentSite = useRecoilValue(currentSiteAtom);
  const listeners = useRef<{ [key: string]: any }>({});

  const [chartColors] = useState<string[]>([
    "rgba(75,192,192,1)",
    "rgba(192,75,192,1)",
    "rgba(192,192,75,1)",
    "rgba(192,75,75,1)",
    "rgba(75,192,75,1)",
    "rgba(75,75,192,1)",
    "rgba(192,75,75,1)",
    "rgba(75,192,75,1)",
  ]);

  // Initialize oledValues with the default value of 180 (corresponding to "1" in the dropdown)
  const [oledValues, setOledValues] = useState<number[]>(Array(7).fill(180));

  const oledOptions = [
    { label: "off", value: -1 },
    { label: "0.41", value: 0 },
    { label: "0.42", value: 10 },
    { label: "0.49", value: 50 },
    { label: "0.61", value: 100 },
    { label: "0.81", value: 150 },
    { label: "1", value: 180 },
    { label: "1.19", value: 200 },
    { label: "1.31", value: 210 },
    { label: "1.46", value: 220 },
    { label: "1.65", value: 230 },
    { label: "1.90", value: 240 },
    { label: "2.24", value: 250 },
  ];

  async function writeToCharacteristic(values: number[]) {
    if (measurementDevice.device) {
      try {
        const server = await measurementDevice.device.gatt?.connect();
        const service = await server?.getPrimaryService(
          EMULATOR_SERVICE3_WRITE
        );
        if (service) {
          for (let i = 0; i < CHARACTERISTIC_DPOT.length; i++) {
            const characteristic = await service.getCharacteristic(
              CHARACTERISTIC_DPOT[i]
            );
            if (characteristic) {
              const value = new DataView(new ArrayBuffer(4));
              value.setInt32(0, values[i], true);
              await characteristic.writeValue(value);
              console.log(`Value written to DPOT${i}`);
            } else {
              console.log(`Characteristic DPOT${i} not found`);
            }
          }
        } else {
          console.log("Service not found");
        }
      } catch (error) {
        console.log("Writing to characteristic failed: " + error);
      }
    } else {
      console.log("No device connected");
    }
  }

  async function signUp() {
    if (deviceCharacteristic) {
      try {
        await writeToCharacteristic(oledValues); // Write values to DPOT characteristics
        for (let i = 0; i < CHARACTERISTIC_OPD.length; i++) {
          const characteristic =
            await deviceCharacteristic.characteristic?.service?.getCharacteristic(
              CHARACTERISTIC_OPD[i]
            );

          if (listeners.current[CHARACTERISTIC_OPD[i]]) {
            characteristic?.removeEventListener(
              "characteristicvaluechanged",
              listeners.current[CHARACTERISTIC_OPD[i]]
            );
          }

          const handleCharacteristicValueChanged = (event: any) => {
            if (!event.target) return;
            const value = event.target.value;
            const va = new DataView(value.buffer);
            const int32Value = va.getInt32(0, true);
            const correctValue = int32Value * Math.pow(10, -6);
            const timestamp = new Date().toISOString();

            // Find the channel index
            const characteristicUuid = event.target.uuid;
            const channelIndex = CHARACTERISTIC_OPD.findIndex(
              (uuid) => uuid === characteristicUuid
            );

            // Add data to the appropriate channel
            setData((prevData) => {
              const newData = [...prevData];
              const channelData = newData[channelIndex];
              newData[channelIndex] = [
                ...channelData,
                { x: channelData.length + 1, y: correctValue },
              ];
              return newData;
            });

            // Add data to the dataToSend
            setDataToSend((prevData) => {
              const newData = { ...prevData };
              const key = `characteristic_${channelIndex}`;
              if (!newData[key]) {
                newData[key] = [];
              }
              newData[key].push({
                value: correctValue,
                timestamp: timestamp,
              });
              return newData;
            });
          };

          listeners.current[CHARACTERISTIC_OPD[i]] =
            handleCharacteristicValueChanged;

          await characteristic!.startNotifications();
          characteristic!.addEventListener(
            "characteristicvaluechanged",
            handleCharacteristicValueChanged
          );
        }
        console.log("Notifications started");
      } catch (error) {
        console.log("Starting notifications failed: " + error);
      }
    } else {
      console.log("No characteristic found yet");
    }
  }

  async function signOut() {
    if (deviceCharacteristic) {
      try {
        for (let i = 0; i < CHARACTERISTIC_OPD.length; i++) {
          const characteristic =
            await deviceCharacteristic.characteristic?.service?.getCharacteristic(
              CHARACTERISTIC_OPD[i]
            );

          if (listeners.current[CHARACTERISTIC_OPD[i]]) {
            characteristic?.removeEventListener(
              "characteristicvaluechanged",
              listeners.current[CHARACTERISTIC_OPD[i]]
            );
          }

          await characteristic!.stopNotifications();
          console.log(`Notifications stopped for channel ${i}`);
        }
        // Switch off all OLEDs
        await writeToCharacteristic(Array(7).fill(-1));
        console.log("All OLEDs switched off");
      } catch (error) {
        console.log("Stopping notifications failed: " + error);
      }
    } else {
      console.log("No characteristic found yet");
    }
  }

  const handleChannelClick = (channel: number) => {
    setSelectedChannel(channel);
  };

  console.log("readMeasures", readMeasures);

  const handleStartButtonClick = () => {
    if (buttonClicked) return;

    if (!readMeasures) {
      signUp();
      setReadMeasures(true);
      setMeasuring(true);
    } else {
      signOut();
      setReadMeasures(false);
      setTestCompleted(true);
      setMeasuring(false);
      setButtonClicked(true);
    }
  };

  const handleOledSelectChange = (index: number, value: number) => {
    setOledValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  };

  const handleResetTest = () => {
    setData(Array.from({ length: 8 }, () => []));
    setDataToSend({});
    setReadMeasures(false);
    setButtonClicked(false);
    setTestCompleted(false);
    setMeasuring(false);
    setSelectedChannel(1);
    setOledValues(Array(7).fill(180));
    signOut();
    console.log("Reset");
  };

  console.log("DATA TO SEND API MEASUREMENT", dataToSend);

  const serializeFormData = (formData: any) => {
    const obj = {};
    // @ts-ignore
    formData.forEach((value, key) => {
      // @ts-ignore
      obj[key] = value;
    });
    return obj;
  };

  const saveResults = async () => {
    try {
      const blob = new Blob([JSON.stringify(dataToSend)], {
        type: "application/json",
      });
      const formData = new FormData();
      formData.append("resultTS", new Date().toISOString());
      formData.append("sitename", currentSite.siteName);
      formData.append("device", "Device X");
      formData.append("cartridge", "Cartridge Y");
      formData.append("filedata", blob, "measurement.json");

      console.log("formData", formData);

      // Serialize formData
      const serializedFormData = serializeFormData(formData);

      // Save serializedFormData to IndexedDB
      const existingMeasurements = (await get("measurements")) || [];
      existingMeasurements.push(serializedFormData);
      await set("measurements", existingMeasurements);
      setResults([...existingMeasurements]);
      console.log("Measurement saved locally", existingMeasurements);
    } catch (error) {
      console.error("Error saving measurement locally:", error);
    }
    navigate(`/organization/${userInfo.currentOrganizationName}/results`);
  };

  const sendResults = async () => {
    try {
      const blob = new Blob([JSON.stringify(dataToSend)], {
        type: "application/json",
      });
      const formData = new FormData();
      formData.append("resultTS", new Date().toISOString());
      formData.append("sitename", currentSite.siteName);
      formData.append("device", "Device X");
      formData.append("cartridge", "Cartridge Y");
      formData.append("filedata", blob, "measurement.json");

      console.log("formData", formData);

      securedApi
        .post(`${API_URL}/api/measurement`, formData)
        .then((res) => {
          console.log("RESPONSE", res);
        })
        .catch((error) => {
          console.error(error.response);
        });
    } catch (error) {
      console.error("Error sending measurement data:", error);
    }
    navigate("/success");
  };

  return (
    <div className="start-new-test">
      <div className="header">
        <h1 style={{ display: "flex", justifyContent: "center" }}>
          {testCompleted
            ? "Measure finished"
            : measuring
            ? "Measuring..."
            : "Create a measurement"}
        </h1>
        {testCompleted && (
          <div style={{ paddingBottom: "2rem" }}>
            <div className="button">
              <Button className="button-go-test" onClick={sendResults}>
                <div
                  style={{ display: "flex", justifyContent: "center", gap: 10 }}
                >
                  <div className="upload-icon">
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                  </div>
                  Send results now
                </div>
              </Button>
            </div>
            <div className="button">
              <Button className="button-go-test" onClick={saveResults}>
                <div
                  style={{ display: "flex", justifyContent: "center", gap: 10 }}
                >
                  <div className="upload-icon">
                    <FontAwesomeIcon icon={faFloppyDisk} />
                  </div>
                  Save results
                </div>
              </Button>
            </div>
            <div className="button">
              <Button className="button-go-test" onClick={handleResetTest}>
                <div
                  style={{ display: "flex", justifyContent: "center", gap: 10 }}
                >
                  <div className="upload-icon">
                    <FontAwesomeIcon icon={faPowerOff} />
                  </div>
                  Reset measurement
                </div>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="pagination-buttons">
        {[...Array(8)].map((_, index) => (
          <Button
            key={index}
            className={`pagination-button ${
              selectedChannel === index + 1 ? "selected" : ""
            }`}
            onClick={() => handleChannelClick(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
      </div>
      {selectedChannel !== null && (
        <DynamicChart
          channel={selectedChannel}
          data={data[selectedChannel - 1]}
          color={chartColors[selectedChannel - 1]}
        />
      )}
      <div style={{ paddingTop: "1rem", paddingBottom: "2rem" }}>
        {!testCompleted ? (
          <div
            className="testing-text"
            style={{ color: "#4a800b", height: "2rem" }}
          >
            {readMeasures ? "Stop Testing" : "Start Testing"}
          </div>
        ) : (
          <div style={{ height: "2rem" }}></div>
        )}
        <button
          disabled={buttonClicked}
          style={{ border: "none", backgroundColor: "transparent" }}
          className={`${buttonClicked ? "opacity-50" : ""}`}
          onClick={handleStartButtonClick}
        >
          {readMeasures ? (
            <FaStop size={50} style={{ color: "#4a800b" }} />
          ) : (
            <FaPlay size={50} style={{ color: "#4a800b" }} />
          )}
        </button>
      </div>
      <Form className="oled-values">
        {[...Array(7)].map((_, index) => (
          <Form.Group
            key={index}
            className="mb-3 d-flex align-items-center oled-row"
            controlId={`oledValue${index}`}
          >
            <Form.Label className="me-2 mt-2">
              Oled {index + 1} current value
            </Form.Label>
            <Form.Select
              className="me-2"
              style={{ width: "120px" }}
              value={oledValues[index]}
              onChange={(e) =>
                handleOledSelectChange(index, parseInt(e.target.value, 10))
              }
              disabled={measuring}
            >
              {oledOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Text>mA</Form.Text>
          </Form.Group>
        ))}
      </Form>
      <div style={{ height: "200px" }}></div>
    </div>
  );
};

export default StartNewTest;
