import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faHome } from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { currentSiteAtom } from "../../recoil/atoms";
import SelectSiteModal from "../../modals/SelectSiteModal/SelectSiteModal";
import { get } from "idb-keyval";

interface SiteModal {
  name: "SelectSite";
}

type VisibleModal = SiteModal | undefined;

const SiteSelect: React.FC = () => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [currentSite, setCurrentSite] = useRecoilState(currentSiteAtom);

  useEffect(() => {
    if (currentSite.siteId === "") {
      get("currentSite")
        .then((site) => {
          if (site.siteId) {
            setCurrentSite(site);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [currentSite.siteId, setCurrentSite]);

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback(() => {
    setVisibleModal({ name: "SelectSite" });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "SelectSite":
          return <SelectSiteModal onClose={handleModalClose} />;
      }
    }
  }, [handleModalClose, visibleModal]);

  return (
    <div className="site-select">
      {currentSite.siteName === "" ? (
        <Container>
          <Row>
            <Col xs={2}>
              <FontAwesomeIcon className="icon" icon={faBan} size="lg" />
            </Col>
            <Col xs={10}>
              <div className="text">No site selected</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button">
                <Button
                  className="button-select-site"
                  onClick={handleModalOpen}
                >
                  Choose Site
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col xs={2}>
              <FontAwesomeIcon className="icon" icon={faHome} size="lg" />
            </Col>
            <Col xs={10}>
              <div className="text">{currentSite.siteName}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="button">
                <Button
                  className="button-select-site"
                  onClick={handleModalOpen}
                >
                  Choose Another Site
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {renderModals()}
    </div>
  );
};

export default SiteSelect;
