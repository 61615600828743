import { atom } from "recoil";

interface UserInfoInterface {
  name: string;
  isAdmin: boolean;
  isManager: boolean;
  organizationsId: string[];
  organizationsName: any[];
  currentOrganizationId: string;
  currentOrganizationName: string;
}

export const userInfoAtom = atom<UserInfoInterface>({
  key: "userInfo",
  default: {
    name: "",
    isAdmin: false,
    isManager: false,
    organizationsId: [],
    organizationsName: [],
    currentOrganizationId: "",
    currentOrganizationName: "",
  } as UserInfoInterface,
});
