import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./style.scss";
import "../style-common.scss";
import ResultsList from "../../components/ResultsList/ResultsList";
import Uploading from "../../components/Uploading/Uploading";

const ResultsPage: React.FC = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  return (
    <div className="results">
      <div className="header">
        Results for Upload
        <FontAwesomeIcon
          className="upload-icon"
          icon={faCloudArrowUp}
          size="sm"
        />
      </div>
      {!isUploading ? (
        <ResultsList setIsUploading={setIsUploading} />
      ) : (
        <Uploading setIsUploading={setIsUploading} />
      )}
    </div>
  );
};

export default ResultsPage;
