import { ResultToSend } from "../types";
import { get } from "idb-keyval";

async function getCurrentEmulatorValue(
  characteristic: BluetoothRemoteGATTCharacteristic | null,
  measurementDevice: any,
  contributor: string
) {
  if (characteristic) {
    const value = await characteristic.readValue();
    const valueArray = new Uint8Array(value.buffer);
    const timestampSection = new Uint8Array(value.buffer).slice(4, 20);
    const timeEncoded = [];
    for (let i = 0; i < timestampSection.length; i++) {
      timeEncoded.push(String.fromCharCode(timestampSection[i]));
    }
    const date = new Date();
    let formatDateISO = date.toISOString();
    // const timestamp = timeEncoded.join("");
    const cartridge = "cartridge" + Math.floor(Math.random() * 10);
    const batch = "batch" + Math.floor(Math.random() * 10);
    // const contributor = "user" + Math.floor(Math.random() * 10);
    const comment = "";
    const tags = undefined;
    const resultError =
      '{"Bifentrin":5.92,"Cylfutrin":6.24,"Lambda-cyhalothrin":7.44,"Fluvalinate":4.66}';
    const resultWarn =
      '{"Bifentrin":1.5,"Cylfutrin":1.00,"Lambda-cyhalothrin":0.9,"Fluvalinate":0.4}';
    const resultOk =
      '{"Bifentrin":1.0,"Cylfutrin":1.00,"Lambda-cyhalothrin":0.9,"Fluvalinate":0.4}';

    const resultOptions = [resultError, resultWarn, resultOk];
    const randomIndex = Math.floor(Math.random() * resultOptions.length);
    const result = resultOptions[randomIndex];

    const resultObj = {} as ResultToSend;

    get("currentSite").then((currentSite) => {
      resultObj.resultTS = formatDateISO;
      resultObj.result = result;
      resultObj.device = measurementDevice.name || measurementDevice.id;
      resultObj.cartridge = cartridge.toString();
      resultObj.batch = batch.toString();
      resultObj.contributor = contributor;
      resultObj.comment = comment.toString();
      resultObj.tags = tags;
      resultObj.status = "X";
    });

    return resultObj;
  } else {
    console.log("No characteristic found yet");
  }
}

export default getCurrentEmulatorValue;
