import React from "react";
import { Button } from "react-bootstrap";
import "../../App.scss";
import "./style.scss";
import { Navigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilValue } from "recoil";

import { userInfoAtom } from "../../recoil/atoms";

const Home: React.FC = () => {
  const { keycloak } = useKeycloak();
  const userInfo = useRecoilValue(userInfoAtom);

  if (keycloak.authenticated && userInfo.currentOrganizationName) {
    return (
      <Navigate
        to={`/organization/${userInfo.currentOrganizationName}/start-page`}
      />
    );
  }

  return (
    <div className="home-page">
      <div className="logo">
        <img
          className="logo-img"
          src={require("../../common/images/welcome_screen_background.png")}
          alt="h-ALO logo"
        />
      </div>
      <div className="button">
        {" "}
        <Button onClick={() => keycloak.login()}>Log In</Button>
      </div>
    </div>
  );
};

export default Home;
