import React from "react";
import "./styles.scss";
import { Button, Form, Modal } from "react-bootstrap";

interface Props {
  onClose: () => void;
  handleSend: () => void;
}

const SendResultsModal: React.FC<Props> = ({ onClose, handleSend }) => {
  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Modal show onHide={onClose} className="modal-send-results">
      <Modal.Header closeButton className="header">
        <Modal.Title>Send all result</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-4">
            <Form.Label>Are you sure you want to send all results?</Form.Label>
          </Form.Group>
          <Button className="btn-modal-save" type="submit" onClick={handleSend}>
            Send
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SendResultsModal;
