import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useLayoutEffect } from "react";

const API_URL: string | undefined = process.env.REACT_APP_API;

export const securedApi = axios.create({ baseURL: API_URL });

export function useApiAuthInterceptor() {
  const keycloak = useKeycloak();

  useLayoutEffect(() => {
    const interceptor = securedApi.interceptors.request.use(
      async (config) => {
        try {
          const storedToken = localStorage.getItem("keycloakToken");

          if (storedToken) {
            config.headers = {
              Authorization: `Bearer ${storedToken}`,
              "Access-Control-Allow-Origin": "*",
            };
          }
        } catch (error) {
          console.error("Error updating access token:", error);
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    return () => securedApi.interceptors.request.eject(interceptor);
  }, [keycloak]);
}
