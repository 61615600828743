import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "idb-keyval";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Col, Container, Row } from "react-bootstrap";

const ResultsToSend: React.FC = () => {
  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    get("measurements")
      .then((resp) => setResultsCount(resp.length))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="results-to-send">
      <Container>
        <Row>
          <Col xs={2}>
            <FontAwesomeIcon className="icon" icon={faFileLines} size="lg" />
          </Col>
          <Col xs={10}>
            <div className="text">
              {resultsCount ? (
                <>
                  You have{" "}
                  <span style={{ fontWeight: "bold" }}>{resultsCount}</span>{" "}
                  test result{resultsCount !== 1 && "s"} to send.
                </>
              ) : (
                "You don't have any test yet to send."
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResultsToSend;
