import React from "react";
import { Image } from "react-bootstrap";
import "../../App.scss";
import "./style.scss";
import "../style-common.scss";

const Success: React.FC = () => {
  return (
    <div className="success-page">
      <h1 className="header">Success!</h1>
      <h3 className="subheader">Your test has been sent to the server.</h3>
      <Image
        className="picture"
        src={require("../../common/images/success_screen_background.png")}
      />
    </div>
  );
};

export default Success;
